import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNextPage, resetFilter, resetUserList, updateFilter } from 'store/user.reducer'
import { useParams } from 'react-router-dom'
import { IoPerson } from 'react-icons/io5'
import { getDateReadFormat, getDateTimeReadFormat } from 'utils/dateFormatting'
import { UserFilter } from './UserFilter'
import {
  getTypeStatusTitle,
  TYPE_ENABLED_COLOR,
  TYPE_USER_COUNTRY,
  TYPE_USER_PLATFORM,
  userIdentificationColor,
  userIdentificationLabel,
} from 'model/user.model'
import { CountryWidget } from 'components/CountryWidget'
import { getFilterParams } from 'utils/getFilterParams'
import { FILTER_USER_PARAMS } from 'config/user.config'
import {
  Container,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import { PersonFullName } from 'chakra-lib/Snippets/elements/PersonFullName'

export const UserList = () => {
  const { params } = useParams()
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.user.loading)
  const userList = useSelector((state) => state.user.list)
  const totalPages = useSelector((state) => state.user.totalPages)
  const activePage = useSelector((state) => state.user.activePage)

  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_USER_PARAMS, params)
      dispatch(updateFilter(objFilterParams))
    } else {
      dispatch(updateFilter())
    }
  }, [params])

  useEffect(() => {
    return () => {
      dispatch(resetUserList())
      dispatch(resetFilter())
    }
  }, [])

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets
            columns="42px minmax(200px, 1fr) 180px 180px 270px 140px 100px 180px  180px 160px 160px 160px 180px 160px 80px 100px"
            itemWidth="2800px"
          >
            <SnippetsHeading>
              <HeadingItem />
              <HeadingItem>ФИО</HeadingItem>
              <HeadingItem>Телефон</HeadingItem>
              <HeadingItem>Страна</HeadingItem>
              <HeadingItem>Идентификация</HeadingItem>
              <HeadingItem>Статус</HeadingItem>
              <HeadingItem>Снят лимит</HeadingItem>
              <HeadingItem>Дата регистрации</HeadingItem>
              <HeadingItem>Дата идентификации</HeadingItem>
              <HeadingItem>Client ID</HeadingItem>
              <HeadingItem>Номер паспорта</HeadingItem>
              <HeadingItem>Номер договора</HeadingItem>
              <HeadingItem>Номер счета</HeadingItem>
              <HeadingItem>Дата выдачи</HeadingItem>
              <HeadingItem>Версия</HeadingItem>
              <HeadingItem>Платформа</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={activePage}
              totalPages={totalPages}
              data={userList}
              onNext={() => dispatch(getNextPage())}
              isLoading={loading}
            >
              {(item) => (
                <SnippetsItem to={`/user/card/0/${item.id}`}>
                  <ItemIcon icon={IoPerson} />
                  <ItemLink>
                    <PersonFullName
                      firstName={item.firstName}
                      lastName={item.lastName}
                      middleName={item.middleName}
                    />
                  </ItemLink>
                  <ItemText>{item.phone}</ItemText>
                  <CountryWidget lang={item.identityCountry}>
                    {TYPE_USER_COUNTRY[item.identityCountry]}
                  </CountryWidget>
                  <ItemText>
                    <ItemBadge colorScheme={userIdentificationColor[item.identification] || 'gray'}>
                      {userIdentificationLabel[item.identification] || item.identification}
                    </ItemBadge>
                  </ItemText>
                  <ItemText>
                    <ItemBadge colorScheme={TYPE_ENABLED_COLOR[item.enabled]}>
                      {getTypeStatusTitle(item)}
                    </ItemBadge>
                  </ItemText>
                  <ItemText>{item.trusted ? 'Да' : 'Нет'}</ItemText>
                  <ItemText>{getDateTimeReadFormat(item.createDate)}</ItemText>
                  <ItemText>{getDateTimeReadFormat(item.identificationDate)}</ItemText>
                  <ItemText>{item.clientId}</ItemText>
                  <ItemText>{item.passport}</ItemText>
                  <ItemText>{item.contractNumber}</ItemText>
                  <ItemText>{item.accountNumber}</ItemText>
                  <ItemText>{getDateReadFormat(item?.dateIssue)}</ItemText>
                  <ItemText>{item.applicationVersion}</ItemText>
                  <ItemText>{TYPE_USER_PLATFORM[item.platform]}</ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <UserFilter params={params} />
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
